import React, { useState } from 'react';
import axios from 'axios';

import Layout from '../components/Layout';
const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const Books = () => {
  const [info, setInfo] = useState({
    program: '',
    fullName: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  });
  const [submitted, setSubitted] = useState(false);

  const onSubmit = async () => {
    setSubitted(true);
    await axios.post(`${API_URL}/books/request`, info);
    return;
  };

  return (
    <Layout fullMenu>
      <article id="main">
        <header style={{ height: 300 }}>
          <h2>Request a Book</h2>
        </header>
        <section className="wrapper style5">
          <div className="inner">
            {submitted ? (
              <div>
                <h2>Your book will arrive in 7-10 business days</h2>
              </div>
            ) : (
              <form>
                <label>
                  Program
                  <select onChange={(e) => setInfo({ ...info, program: e.target.value })}>
                    <option value="0">1. Select Program</option>
                    <option value="engineering">Florida PE License Renewal</option>
                    <option value="cosmetology">Florida Cosmetology License Renewal</option>
                    <option value="Veterinarians">Florida Vet License Renewal</option>
                  </select>
                </label>
                <label>
                  Full Name
                  <input type="text" name="name" id="name" onChange={(e) => setInfo({ ...info, fullName: e.target.value })} />
                </label>
                <label>
                  Email
                  <input type="email" name="email" id="email" onChange={(e) => setInfo({ ...info, email: e.target.value })} />
                </label>
                <label>
                  Address
                  <input type="text" name="subject" id="subject" onChange={(e) => setInfo({ ...info, address: e.target.value })} />
                </label>
                <label>
                  City
                  <input type="text" name="message" id="message" onChange={(e) => setInfo({ ...info, city: e.target.value })} />
                </label>
                <label>
                  State
                  <input type="text" name="message" id="message" onChange={(e) => setInfo({ ...info, state: e.target.value })} />
                </label>
                <label>
                  Zip
                  <input type="text" name="message" id="message" onChange={(e) => setInfo({ ...info, zip: e.target.value })} />
                </label>

                <button type="button" onClick={onSubmit}>
                  Submit
                </button>
              </form>
            )}
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default Books;
